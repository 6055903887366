@import "../../scss/_variables.scss";

.register-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: linear-gradient(135deg, #328589, #28686b);
}

.register-card {
  background-color: #fff;
  padding: 2.5rem;
  border-radius: 12px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  text-align: center;
  width: 100%;
  max-width: 420px;
  animation: fade-in 0.5s ease;

  h2 {
    font-size: 2rem;
    margin-bottom: 0.5rem;
    color: #333;
  }

  p {
    color: #666;
    margin-bottom: 1.5rem;
  }

  .input-group {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-bottom: 1rem;

    label {
      font-size: 0.9rem;
      color: #555;
      margin-bottom: 0.3rem;
    }

    input {
      padding: 0.75rem;
      font-size: 1rem;
      border: 1px solid #ddd;
      border-radius: 8px;
      outline: none;
      transition: border-color 0.3s ease;

      &:focus {
        border-color: #69a98a;
      }
    }
  }

  .register-button {
    padding: 0.75rem;
    width: 100%;
    font-size: 1rem;
    color: #fff;
    background-color: $main-turqoise;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;

    &:hover {
      background-color: $main-dark-turqoise;
      transform: translateY(-2px);
    }

    &:active {
      background-color: #2e5738;
      transform: translateY(0px);
    }
  }

  .social-register-button {
    padding: 0.75rem;
    width: 100%;
    font-size: 1rem;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: transform 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    margin-top: 1rem;

    .icons {
      margin-left: 10px;
      font-size: 1.5rem;
    }

    &:hover {
      transform: translateY(-2px);
    }

    &:active {
      transform: translateY(0px);
    }

    &.google {
      background-color: #a32d2d;
      color: #fff;
      margin-top: 1.5rem;

      &:hover {
        background-color: #c3382a;
      }
    }

    &.apple {
      background-color: #333;
      color: #fff;

      &:hover {
        background-color: #444;
      }
    }
  }

  .master-toggle {
    display: flex;
    align-items: center;
    margin-top: 1.5rem;
    justify-content: center;

    label {
      font-size: 1rem;
      color: #555;
      margin-right: 0.5rem;
      font-weight: bold;
    }   
  }

  .login-link {
    margin-top: 1.5rem;
    font-size: 0.9rem;
    color: #666;

    a {
      color: $main-turqoise;
      text-decoration: none;
      font-weight: bold;
      transition: color 0.3s ease;

      &:hover {
        color: $main-dark-turqoise;
      }
    }
  }
}

/* Giriş animasyonu */
@keyframes fade-in {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
