.alert {
    position: fixed;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    padding: 15px 30px;
    margin: 10px 0;
    border-radius: 8px;
    font-size: 16px;
    font-weight: bold;
    display: inline-block;
    color: #fff;
    max-width: 90%;
    z-index: 9999;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
    
    &.success {
      background-color: #28a745;
    }
    
    &.error {
      background-color: #dc3545;
    }
    
    &.warning {
      background-color: #ffc107;
      color: #212529;
    }
  }
  