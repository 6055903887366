@import "../../scss/variables";

.main-enterance {
    display: flex;
    flex-direction: column;
    position: relative;
    h1 {
        text-align: center;
        font-size: 2rem;
        z-index: 3;
        position: absolute;
        color: white;
        background-color: $main-turqoise;
        opacity: 0.9;
        border-radius: 0 0 0.5rem 0.5rem;
        padding: 1rem 2rem;
        left: 50%;
        transform: translateX(-50%);
    }

    .box-container {
        display: flex;
        .buttons {
            position: relative;
            width: 50%;
            height: 100vh;
            background-size: cover;
            background-repeat: no-repeat;
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            transition: all ease-in-out 0.1s;

            &:before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background: inherit;
                filter: blur(3px);
                border-radius: 1rem;
                z-index: 1;
                transition: all ease-in-out 0.3s;
            }

            &:hover:before {
                filter: none;
                transform: scale(1.02);
            }

            h2 {
                color: #fff;
                position: relative;
                text-align: center;
                background-color: $main-turqoise;
                font-size: 2rem;
                border-radius: 0.2rem;
                padding: 0.5rem;
                z-index: 2;
                margin: 0;
            }
        }

        a {
            text-decoration: none;
            color: inherit;
        }

        .search-job {
            background-image: url("../../assets/enterancephoto/postjob.jpg");
        }

        .post-job-main {
            background-image: url("../../assets/enterancephoto/searchjob.jpg");
        }
    }
}
