.my-jobs {
  margin-top: 2rem;
  padding: 20px;
  background-color: #f8f8f8;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  
  h1 {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 20px;
    color: #333;
  }

  .card-container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 1rem;

    .job-card {
      background-color: #fff;
      padding: 20px;
      margin-bottom: 20px;
      border-radius: 8px;
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);

      h2 {
        font-size: 1.6rem;
        margin-bottom: 10px;
        color: #4CAF50; // Job title color
      }

      p {
        font-size: 1rem;
        color: #555;
        margin: 5px 0;

        strong {
          color: #333;
        }
      }

      small {
        display: block;
        margin-top: 10px;
        font-size: 0.9rem;
        color: #888;
      }

      .trash {
        margin-top: 10px;
        cursor: pointer;
        color: #e74c3c;
        transition: color 0.3s;
        
        &:hover {
          color: #c0392b;
        }
      }
    }
  }

  .loading,
  .error-message {
    text-align: center;
    font-size: 1.2rem;
    margin: 20px 0;
    color: #ff5722;
  }

  .error-message {
    color: #f44336; // Error color
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;

    li {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .no-jobs {
    text-align: center;
    font-size: 1.2rem;
    color: #888;
    padding: 20px 0;
  }
}
