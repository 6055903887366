@import "../../scss/variables";

.support-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  background-color: #f9f9f9;
  min-height: 100vh;

  h1 {
    font-size: 2rem;
    margin-bottom: 1.5rem;
    color: #333;
  }

  .support-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 600px;

    .support-input {
      width: 100%;
      padding: 1rem;
      font-size: 1rem;
      border: 1px solid #ccc;
      border-radius: 5px;
      margin-bottom: 1rem;

      &:focus {
        outline: none;
        border-color: $main-turqoise;
      }
    }

    .support-textarea {
      width: 100%;
      min-height: 150px;
      padding: 1rem;
      font-size: 1rem;
      border: 1px solid #ccc;
      border-radius: 5px;
      resize: none;
      margin-bottom: 1rem;

      &:focus {
        outline: none;
        border-color: $main-turqoise;
      }
    }

    .support-submit-button {
      padding: 0.75rem 1.5rem;
      background-color: $main-turqoise;
      color: #fff;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      font-size: 1rem;
      font-weight: bold;
      transition: background-color 0.3s;

      &:hover {
        background-color: $main-dark-turqoise;
      }

      &:disabled {
        background-color: #9e9e9e;
        cursor: not-allowed;
      }
    }
  }

  .support-message {
    margin-top: 1rem;
    font-size: 1rem;
    color: $main-turqoise;
    text-align: center;
  }
}
