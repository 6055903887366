.find-job {
  padding: 2rem;
  max-width: 800px;
  margin: 0 auto;
  font-family: Arial, sans-serif;
  color: #333;

  h1 {
    text-align: center;
    margin-bottom: 2rem;
    color: #2c3e50;
  }

  .stepper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;

    .step {
      flex: 1;
      text-align: center;
      padding: 1rem;
      border-radius: 5px;
      background-color: #f5f5f5;
      border: 1px solid #ccc;
      color: #777;
      font-size: 0.9rem;
      transition: all 0.3s ease;

      &.active {
        background-color: #2ecc71;
        color: #fff;
        border-color: #27ae60;
        font-weight: bold;
      }

      &:not(:last-child) {
        margin-right: 0.5rem;
      }
    }
  }

  .form {
    margin-bottom: 2rem;

    .form-group {
      margin-bottom: 1.5rem;

      label {
        display: block;
        font-weight: bold;
        margin-bottom: 0.5rem;
        color: #34495e;
      }

      select,
      textarea {
        width: 100%;
        padding: 0.8rem;
        font-size: 1rem;
        border: 1px solid #ddd;
        border-radius: 5px;
        background-color: #f9f9f9;
        transition: all 0.3s ease;

        &:focus {
          border-color: #3498db;
          background-color: #fff;
          box-shadow: 0 0 5px rgba(52, 152, 219, 0.5);
        }
      }

      textarea {
        height: 100px;
        resize: vertical;
      }
    }
  }

  .results {
    .result {
      display: flex;
      align-items: center;
      padding: 1rem;
      margin-bottom: 1rem;
      border: 1px solid #ddd;
      border-radius: 5px;
      background-color: #fdfdfd;
      transition: all 0.3s ease;

      &:hover {
        border-color: #3498db;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
      }

      .profile-picture {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        margin-right: 1rem;
        object-fit: cover;
      }

      .details {
        h3 {
          font-size: 1.2rem;
          color: #2c3e50;
          margin-bottom: 0.5rem;
        }

        p {
          margin: 0.2rem 0;
          font-size: 0.9rem;
          color: #7f8c8d;

          strong {
            color: #2c3e50;
          }

          em {
            font-style: italic;
            color: #95a5a6;
          }
        }
      }
    }

    h2 {
      text-align: center;
      margin-bottom: 1rem;
      color: #27ae60;
    }

    .error {
      text-align: center;
      color: #e74c3c;
      font-weight: bold;
    }
  }

  .buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;

    button {
      flex: 1;
      padding: 12px 20px;
      font-size: 1rem;
      font-weight: bold;
      border: none;
      border-radius: 8px;
      cursor: pointer;
      transition: all 0.3s;
      margin: 0 5px;

      &:nth-child(1) {
        background-color: #f44336;
        color: white;

        &:hover {
          background-color: #d32f2f;
        }
      }

      &:nth-child(2) {
        background-color: #03a9f4;
        color: white;

        &:hover {
          background-color: #0288d1;
        }
      }

      &:last-child {
        background-color: #4caf50;
        color: white;

        &:hover {
          background-color: #388e3c;
        }
      }

      &:disabled {
        background: #ccc;
        cursor: not-allowed;
      }
    }
  }
}
