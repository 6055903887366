@import "../../scss/variables";

.update-profile-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  
    h1 {
      font-size: 24px;
      margin-bottom: 20px;
      text-align: center;
      color: #333;
    }
  
    .form-group {
      margin-bottom: 15px;
  
      label {
        display: block;
        font-size: 14px;
        margin-bottom: 5px;
        color: #555;
      }
  
      input, select {
        width: 100%;
        padding: 10px;
        font-size: 14px;
        border: 1px solid #ccc;
        border-radius: 5px;
        outline: none;
        transition: border-color 0.3s ease;
  
        &:focus {
          border-color: #007bff;
        }
      }

      select {
        appearance: none;
        background-color: #fff;
        cursor: pointer;
      }
    }
  
    .form-actions {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
  
      .nbutton {
        padding: 10px 15px;
        font-size: 14px;
        color: #fff;
        background-color: #007bff;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s ease;
  
        &.save-button {
          background-color: #28a745;
  
          &:hover {
            background-color: #218838;
          }
        }
  
        &.delete-button {
          background-color: #dc3545;
  
          &:hover {
            background-color: #c82333;
          }
        }
  
        &:hover {
          background-color: darken($main-turqoise, 10%);
        }
      }
    }
  }
