.user-profile {
  .cover-picture {
    width: 100%;
    height: 200px;
    overflow: hidden;

    img {
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  }

  .profile-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;

    .profile-picture {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      margin-bottom: 10px;
    }

    h2 {
      font-size: 24px;
      margin-bottom: 10px;
    }

    p {
      font-size: 14px;
      color: #555;
    }
  }
}
