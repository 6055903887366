@import "../../scss/variables";

// Fade-in and fade-out keyframes
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.follow-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin: 20px 0;

  .follow-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    padding: 10px 20px;
    border: 2px solid $main-turqoise;
    border-radius: 8px;
    background-color: #f9f9f9;
    transition: transform 0.3s ease, background-color 0.3s ease;

    &:hover {
      transform: scale(1.05);
      background-color: #e8f5f4;
    }

    .follow-count {
      font-size: 24px;
      font-weight: bold;
      color: $main-turqoise;
    }

    .follow-label {
      font-size: 16px;
      color: #555;
    }
  }

  .follow-button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    &.follow {
      background-color: #4caf50;
      color: white;
    }
    &.unfollow {
      background-color: #f44336;
      color: white;
    }
  }
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 500px;
  background-color: #fff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  z-index: 1000;

  // Apply fade-in animation
  animation: fadeIn 0.2s ease-out forwards;

  .modal-content {
    padding: 20px;
    position: relative;

    h3 {
      text-align: center;
      margin-bottom: 20px;
      font-size: 20px;
      color: #333;
    }

    .modal-close {
      position: absolute;
      top: 10px;
      right: 10px;
      background: none;
      border: none;
      font-size: 20px;
      cursor: pointer;
      color: #666;

      &:hover {
        color: #000;
      }
    }

    .list {
      display: flex;
      flex-direction: column;
      gap: 60px; /* Elemanlar arası boşluk */
      padding: 10px 15px; /* İç boşluk */
      margin: 0;
      max-height: 300px; /* Maksimum yükseklik */
      // overflow-y: auto; /* Çok eleman varsa kaydırma */
      scrollbar-width: thin; /* İnce kaydırma çubuğu */
      scrollbar-color: $main-turqoise #f9f9f9;

      &::-webkit-scrollbar {
        width: 8px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #4caf50;
        border-radius: 10px;
      }
      &::-webkit-scrollbar-track {
        background-color: #f9f9f9;
      }
    }

    .list-item {
      display: flex;
      align-items: center;
      gap: 15px; /* Profil resmi ve metin arası boşluk */
      padding: 10px;
      background-color: #ffffff; /* Beyaz arka plan */
      border-radius: 8px; /* Yuvarlatılmış köşeler */
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Hafif gölge */
      transition: transform 0.3s ease, background-color 0.3s ease;

      &:hover {
        background-color: #f0f4f8;
        transform: scale(1.015); /* Hafif büyüme */
      }

      .profile-picture {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        object-fit: cover; /* Resmi düzgün şekilde sığdır */
        border: 2px solid $main-turqoise; /* Yeşil çerçeve */
      }

      .full-name {
        font-size: 16px;
        color: #333;
        font-weight: 500;
        flex-grow: 1; /* Metin alanını genişlet */
        text-align: left; /* Metni sola hizala */
      }
    }
  }
}

@keyframes fadeIn {
    from {
      opacity: 0;
      transform: translate(-50%, -50%) scale(0.9);
    }
    to {
      opacity: 1;
      transform: translate(-50%, -50%) scale(1);
    }
  }
