// @import url('https://fonts.googleapis.com/css2?family=Barlow&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100..900&display=swap');



$primaryTextColor: #dbd8e3;
$backgroundColor: #fff;
$backgroundColor2: #F1EBE3;
$textColor: #ecf0f1;

$waColor: #017561;
$gray: #665b5f;
$black: #000000;
$fistik: #efffe1;

//blue palette
$blue4:#002843;
$blue3:#005e90;
$blue2:#009de8;
$blue1:#00e3ff;

//orange palette;
$orange4:#D95407;
$orange3:#F28705;
$orange2:#F29F05;
$orange1:#F2B705;
$monaOrange: #F0900C;


//purple palette;
$darkPurple: #750275;
$monaPurple: #662F94;
$purple4: #854ddc;
$purple3: #9368d8;
$purple2: #9e7fd1;
$purple1: #b19fce;


// blog variables;
$background-dark: #2d3548;
$text-light: rgba(255, 255, 255, 0.6);
$text-lighter: rgba(255, 255, 255, 0.9);
$spacing-s: 8px;
$spacing-m: 16px;
$spacing-l: 24px;
$spacing-xl: 32px;
$spacing-xxl: 64px;
$width-container: 1200px;


// findpro variables;

$main-turqoise: #328589;
$main-dark-turqoise:#28686b;

