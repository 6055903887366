@import "../../scss/_variables.scss";

.login-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: linear-gradient(135deg, #328589, #28686b);
}

.login-card {
  background-color: #ffffff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  text-align: center;
  width: 100%;
  max-width: 400px;
  animation: slide-in 0.5s ease-out; // Animasyonu biraz daha yumuşattım

  h2 {
    font-size: 1.8rem;
    margin-bottom: 0.5rem;
    color: #333;
  }

  p {
    color: #666;
    margin-bottom: 1.5rem;
  }

  .input-group {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-bottom: 1.5rem; // Daha fazla alan bıraktım

    label {
      font-size: 0.9rem;
      color: #555;
      margin-bottom: 0.3rem;
    }

    input {
      padding: 0.75rem;
      font-size: 1rem;
      border: 1px solid #ddd;
      border-radius: 8px;
      outline: none;
      transition: border-color 0.3s ease;

      &:focus {
        border-color: $main-turqoise; // Kendi renk değişkeninizi kullanabilirsiniz
      }
    }
  }

  .login-button {
    padding: 0.75rem;
    width: 100%;
    font-size: 1rem;
    color: #ffffff;
    background-color: $main-turqoise;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;

    &:hover {
      background-color: $main-dark-turqoise;
      transform: translateY(-2px);
    }

    &:active {
      background-color: #145a1a;
      transform: translateY(0px);
    }
  }

  .or-text {
    margin: 1.5rem 0;
    color: #888;
    font-size: 0.9rem;
  }

  .social-login {
    width: 100%;
    padding: 0.75rem;
    margin-bottom: 1rem;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    color: #fff;
    font-weight: bold;
    transition: all 0.2s ease-in-out;

    &.google-login {
      background-color: #db4437; // Google kırmızı
    }

    &.apple-login {
      background-color: #333333; // Siyah
    }

    &:hover {
      opacity: 0.9;
      transform: translateY(-2px);
    }
  }

  .register-link {
    margin-top: 1.5rem;
    font-size: 0.9rem;
    color: #666;

    a {
      color: $main-turqoise;
      text-decoration: none;
      font-weight: bold;
      transition: color 0.3s ease;

      &:hover {
        color: $main-dark-turqoise;
      }
    }
  }
}

/* Giriş animasyonu */
@keyframes slide-in {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* İkonlar için özel stiller */
.icons {
  display: inline-block;
  font-size: 1.5rem;
  margin-left: 10px;
  vertical-align: middle; /* İkonları yatay olarak hizalamak için */
}

.icons-google {
  color: #fff; /* Google için beyaz renk */
}

.icons-apple {
  color: #fff; /* Apple için beyaz renk */
}

.social-login span.icons {
  margin-left: 10px; /* Sosyal butonların ikonları arasındaki boşluk */
}
