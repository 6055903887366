@import "../../scss/variables";

.main-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .first-temp {
        background-image: url('../../assets/homewall.jpeg');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        .support {
            position: absolute;
            top: 1rem;
            right: 1rem;
            
            a {
                display: inline-flex;
                align-items: center;
                gap: 0.5rem;
                padding: 0.75rem 2rem;
                font-size: 1.2rem;
                font-weight: bold;
                color: #fff;
                text-decoration: none;
                background-color: $main-turqoise;
                border-radius: 8px;
                box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
                transition: all 0.3s ease;
        
                svg {
                    font-size: 1.5rem;
                }
        
                &:hover {
                    background-color: $main-dark-turqoise;
                    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.3);
                    transform: translateY(-2px);
                }
        

            }
        }

        .buttons {
            display: flex;
            gap: 1rem;
            margin-top: auto;
            margin-bottom: 2rem;

            button {
                padding: 0.75rem 1.5rem;
                font-size: 1.2rem;
                font-weight: bold;
                color: #fff;
                background-color: #328589;
                border: none;
                border-radius: 8px;
                box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
                cursor: pointer;
                transition: all 0.3s ease;

                &:hover {
                    background-color: #28686b;
                    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.3);
                    transform: translateY(-2px);
                }

                &:active {
                    background-color: #28686b;
                    transform: translateY(0px);
                }
            }
        }
    }

    .purpose {
        text-align: center;
        h1{
            color: $main-turqoise;
        }

        p{
            text-align: center;
            font-style: italic;
            padding: 1rem 3rem;
        }
    }
}
