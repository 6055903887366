.post-job {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  
    h1 {
      font-size: 24px;
      color: #333;
      text-align: center;
      margin-bottom: 20px;
    }
  
    form {
      .form-group {
        margin-bottom: 15px;
  
        label {
          font-weight: bold;
          display: block;
          margin-bottom: 5px;
          font-size: 16px;
          color: #555;
        }
  
        select,
        textarea {
          width: 100%;
          padding: 10px;
          font-size: 14px;
          color: #333;
          border: 1px solid #ccc;
          border-radius: 4px;
          background-color: #fff;
          transition: border-color 0.2s ease;
  
          &:focus {
            border-color: #008080; // Dark Green Highlight
            outline: none;
          }
        }
  
        textarea {
          resize: vertical;
          min-height: 80px;
        }
      }
  
      .submit-button {
        width: 100%;
        padding: 12px;
        font-size: 16px;
        font-weight: bold;
        color: #fff;
        background-color: #008080; // Dark Green
        border: none;
        border-radius: 4px;
        cursor: pointer;
        transition: background-color 0.2s ease;
  
        &:hover {
          background-color: #006666; // Darker Green
        }
  
        &:focus {
          outline: none;
        }
      }
    }
  }
  