@import "../../scss/variables";
.profile-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f5f5f5;
    padding: 20px;
    min-height: 100vh;
  
    .cover-picture {
      width: 100%;
      height: 200px;
      overflow: hidden;
      img.cover-img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  
    .profile-card {
      background: #fff;
      border-radius: 10px;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      margin-top: -50px;
      padding: 20px;
      width: 90%;
      max-width: 400px;
      text-align: center;
  
      .profile-picture {
        margin: -60px auto 10px;
        width: 120px;
        height: 120px;
        border-radius: 50%;
        overflow: hidden;
        border: 5px solid #fff;
        img.profile-img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
  
      h2 {
        font-size: 24px;
        color: #333;
      }
  
      .email {
        font-size: 14px;
        color: #777;
        margin-bottom: 20px;
      }
  
      .profile-details {
        margin: 20px 0;
        text-align: left;
  
        .profile-field {
          display: flex;
          justify-content: space-between;
          margin-bottom: 10px;
  
          .label {
            font-weight: bold;
            color: #555;
          }
  
          .value {
            color: #777;
          }
        }
      }
  
      .action-buttons {
        display: flex;
        justify-content: space-between;
        gap: 10px;
  
        .nbutton {
          flex: 1;
          padding: 10px 15px;
          border: none;
          border-radius: 5px;
          font-size: 14px;
          text-decoration: none;
          cursor: pointer;
        }
  
        .edit-button {
          background-color: $main-turqoise;
          color: #fff;
        }
  
        .logout-button {
          background-color: #f44336;
          color: #fff;
        }
      }
    }
  }
  