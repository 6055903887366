@import "../../scss/variables";

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: $main-turqoise;

  &__logo img {
    height: 50px;
    border-radius: 1rem;
    transition: all ease-in-out 0.2s;

    &:hover {
      transform: scale(1.02);
      opacity: 0.9;
    }
  }

  &__buttons {
    display: flex;
    gap: 1rem;

    .navbar__button {
      padding: 0.5rem 1rem;
      color: white;
      background-color: transparent;
      border: 1px solid white;
      border-radius: 5px;
      text-decoration: none;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: rgba(255, 255, 255, 0.1);
      }
    }

    .logout {
      border-color: red;
      color: red;
      cursor: pointer;

      &:hover {
        background-color: rgba(255, 0, 0, 0.1);
      }
    }
  }

  &__welcome {
    font-size: 1.1rem;
    font-weight: bold;
    color: white;
    text-align: center;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.2); /* Hafif bir arka plan */
    transition: background-color 0.3s ease, transform 0.2s ease;

    &:hover {
      cursor: default;
    }
  }
}

@media (max-width: 768px) {
  .navbar {
    flex-direction: column;
    padding: 0.5rem;

    &__buttons {
      flex-direction: column;
      gap: 0.5rem;
      width: 100%;

      .navbar__button {
        text-align: center;
        width: 100%;
      }
    }

    &__welcome {
      margin-top: 1rem;
      font-size: 1rem; /* Mobilde biraz daha küçük font */
    }
  }
}
